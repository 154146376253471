import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
  static targets = ['input', 'showOption', 'hideOption']

  connect() {
    this.hidden = true
    this.initializeMask()
    this.updateOptionVisibility()
  }

  initializeMask() {
    this.mask = IMask(this.inputTarget, {
      mask: 'XXX-XX-0000',
      definitions: {
        'X': {
          mask: '0',
          displayChar: 'X',
          placeholderChar: '#'
        }
      },
      lazy: false,
      overwrite: 'shift'
    })

    this.createHiddenInput()

    this.mask.on('accept', () => {
      if (this.hiddenInput) {
        this.hiddenInput.value = this.mask.unmaskedValue.replace(/\D/g, '')
      }
    })
  }

  toggle(event) {
    event.preventDefault()
    this.hidden = !this.hidden
    this.updateOptionVisibility()

    const currentValue = this.hiddenInput.value
    const originalName = this.hiddenInput.name

    if (this.hidden) {
      this.mask.destroy()
      this.initializeMask()
      this.hiddenInput.name = originalName
      this.mask.value = currentValue.replace(/\D/g, '')
    } else {
      const formattedValue = currentValue.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
      this.inputTarget.value = formattedValue
    }

    this.hiddenInput.value = currentValue.replace(/\D/g, '')
  }

  updateOptionVisibility() {
    this.hideOptionTarget.hidden = this.hidden
    this.showOptionTarget.hidden = !this.hidden
  }


  createHiddenInput() {
    const hiddenInputId = `${this.inputTarget.id}_hidden`
    const existingHidden = this.element.querySelector(`#${hiddenInputId}`)
    if (existingHidden) {
      existingHidden.remove()
    }

    this.hiddenInput = document.createElement('input')
    this.hiddenInput.type = 'hidden'
    this.hiddenInput.id = hiddenInputId
    this.hiddenInput.name = this.inputTarget.name

    this.hiddenInput.value = this.mask.unmaskedValue.replace(/\D/g, '')

    this.inputTarget.name = `${this.inputTarget.name.replace(/_display.*$/, '')}_display`

    this.inputTarget.parentNode.appendChild(this.hiddenInput)
  }
}
