// Adds/updates a pendo attribute on all Bootstrap Custom Switches to show input value since Pendo
// is not able to view/access the related checkbox input.
// @see https://getbootstrap.com/docs/4.6/components/forms/#switches
(() => {
  const pendoAttrName = "data-pendo-value";

  const addPendoAttribute = () => {
    const allSwitches = document.querySelectorAll(`.form-switch:not([${pendoAttrName}])`);
    allSwitches.forEach((switchElement) => {
      const input = switchElement.querySelector('input[type="checkbox"]');
      if (input) {
        switchElement.setAttribute(pendoAttrName, input.checked);
        input.addEventListener("change", () => {
          switchElement.setAttribute(pendoAttrName, input.checked);
        });
      }
    });
  };
  addPendoAttribute();

  const observer = new MutationObserver(addPendoAttribute);
  observer.observe(document.querySelector("body"), {
    childList: true,
    subtree: true
  });
})();
