import { Controller } from "@hotwired/stimulus";
// This controller manages the enabling and disabling of a submit button
// based on whether a value has been selected from a dropdown (select input).
// The submit button is initially disabled and will only be enabled if a valid
// option (not the default blank or empty option) is selected in the dropdown.
//
// Example Form:
// 
// <%= simple_form_for @employee, html: { data: { controller: 'enable-submit-button' } } do |form| %>
//   <%= form.input(
//     :manager_id,
//     as: :select,
//     required: false,
//     label: 'Choose a manager',
//     include_blank: 'Choose a manager to continue',
//     collection: User.manager_options_array(account_id: current_user.account_id).sort_by(&:name),
//     input_html: { data: { target: 'enable-submit-button.select', action: 'change->enable-submit-button#toggleSubmitButton' } }
//   ) %>
// 
//   <%= form.submit "Submit", id: 'submit_button', disabled: true, class: 'btn btn-primary', data: { target: 'enable-submit-button.submitButton' } %>
// <% end %>
export default class extends Controller {
  static targets = ["select", "submitButton"];

  connect() {
    // Disable the submit button initially
    this.submitButtonTarget.disabled = true;
  }

  toggleSubmitButton() {
    // Enable submit button only if a dropdown option is selected
    if (this.selectTarget.value !== "") {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
