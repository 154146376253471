import { Controller } from '@hotwired/stimulus';
import $ from "jquery";
/**
 * Toggles the collapse class of a group of bootstrap-collapse instances
 */
export default class extends Controller {
  static values = {
    button: String,
    class: String
  };

  toggleAllPlans() {
    const toggleAllButton = document.getElementById(this.buttonValue);
    const collapses = document.querySelectorAll(`#${this.classValue} .collapse`);
    let areAllCollapsed = toggleAllButton.textContent == "Expand All";

    if (areAllCollapsed) {
      collapses.forEach(function (collapseElement) {
        $(collapseElement).collapse('show');
      });
      toggleAllButton.textContent = "Collapse All";
    } else {
      collapses.forEach(function (collapseElement) {
        $(collapseElement).collapse('hide');
      });
      toggleAllButton.textContent = "Expand All";
    }
  }
}
