import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "slider", "leftText", "rightText"];

  toggle() {
    this.setToggleState(this.inputTarget.checked);
  }

  setToggleState(isAfter) {
    this.sliderTarget.style.transform = isAfter ? "translateX(100%)" : "translateX(0)";

    this.leftTextTarget.classList.remove("text-black", "ui-01");
    this.rightTextTarget.classList.remove("text-black", "ui-01");

    this.leftTextTarget.classList.add(isAfter ? "text-black" : "ui-01");
    this.rightTextTarget.classList.add(isAfter ? "ui-01" : "text-black");
  }

  handleRoleChange(event) {
    const dueDaysInput = this.element.querySelector('input[name*="due_days"]');

    // If the due days input is populated, do not toggle the switch.
    // This is to prevent altering user input.
    if (dueDaysInput && dueDaysInput.value !== '') return;

    const role = event.currentTarget.value;
    this.inputTarget.checked = role === 'employee';
    this.setToggleState(role === 'employee');
  }
}
