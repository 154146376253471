import { Controller } from "@hotwired/stimulus"

/**
 * Controller for handling automatic form saving.
 * Automatically saves form changes after user input stops and handles
 * explicit submission events.
 *
 * @example
 *   <div data-controller="autosave-form" data-autosave-form-timeout-value="300">
 *     <%= form.input :name, input_html: {
 *       data: { action: 'input->autosave-form#save' }
 *     } %>
 *   </div>
 */
export default class extends Controller {
  static targets = ["form"]
  static values = {
    timeout: { type: Number, default: 2000 }
  }

  connect() {
    this.timeoutId = null
    this.hasUnsavedChanges = false
    this.isSubmitting = false
    this.pendingChanges = false // Track changes during submission
  }

  save(event) {
    if (this.isSubmitting) {
      // With a 2 second timeout, this shouldn't happen often
      // But this will prevent changes from being lost
      this.pendingChanges = true
      return
    }

    if (event.repeat) return;

    // Handle explicit submission events to prevent duplicate submissions
    if (event.type === 'keydown' && event.key === 'Enter') {
      event.preventDefault()
      this.submitForm()
      return
    }

    if (event.type === 'blur') {
      this.submitForm()
      return
    }

    clearTimeout(this.timeoutId)
    this.hasUnsavedChanges = true

    this.timeoutId = setTimeout(() => {
      this.submitForm()
    }, this.timeoutValue)
  }

  async submitForm() {
    if (this.hasUnsavedChanges && !this.isSubmitting) {
      this.isSubmitting = true
      this.hasUnsavedChanges = false
      try {
        await this.formTarget.requestSubmit()
      } finally {
        this.isSubmitting = false
        this.hasUnsavedChanges = false

        if (this.pendingChanges) {
          this.pendingChanges = false
          this.save({ type: 'input' })
        }
      }
    }
  }

  disconnect() {
    clearTimeout(this.timeoutId)
  }
}
