import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"]

  toggle(event) {
    const activeClass = this.element.dataset.activeClass || 'active'
    const clickedTab = event.currentTarget
    const clickedIndex = this.tabTargets.indexOf(clickedTab)
    
    // Group tabs by type (buttons vs content)
    const tabButtons = this.tabTargets.filter(tab => tab.classList.contains('tab-button'))
    const tabContents = this.tabTargets.filter(tab => tab.classList.contains('tab-content'))
    
    // Update buttons
    tabButtons.forEach((tab, index) => {
      if (index === clickedIndex) {
        tab.classList.add(activeClass)
      } else {
        tab.classList.remove(activeClass)
      }
    })
    
    // Update content panels
    tabContents.forEach((content, index) => {
      if (index === clickedIndex) {
        content.classList.add(activeClass)
      } else {
        content.classList.remove(activeClass)
      }
    })
    
    // Emit custom event with tab name
    const tabName = clickedTab.textContent.trim().toLowerCase()
    const customEvent = new CustomEvent('tab-changed', { 
      detail: { tab: tabName },
      bubbles: true
    })
    this.element.dispatchEvent(customEvent)
  }
}
