import { Controller } from "@hotwired/stimulus";

/**
 * VisibilityController is to be used when you want to toggle the visibility
 * of an element while preserving its space in the DOM. This controller toggles
 * the `visibility` CSS property of the target element between `hidden` and `visible`.
 *
 * @example <caption>Basic example with toggle button.</caption>
 *
 * <div data-controller="visibility">
 *   <button type="button" data-action="click->visibility#toggle">
 *     Toggle Visibility
 *   </button>
 *
 *   <div data-visibility-target="toggleable" class="content">
 *     This content will toggle visibility but still occupy space.
 *   </div>
 * </div>
 *
 */

export default class extends Controller {
  static targets = ["toggleable"];

  toggle() {
    this.toggleableTarget.style.visibility =
      this.toggleableTarget.style.visibility === "hidden" ? "visible" : "hidden";
  }
}
