import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = [
    "option",
    "current",
    "oneTime",
    "oneTimeFormField",
    "currentHolder",
    "oneTimeHolder",
    "currentDataHolder",
    "defaultToggle"
  ];

  static values = {
    useOneTime: Boolean,
    appId: String,
    interviewer: String
  }

  connect() {
    // set up default
    if(!this.hasOneTimeFormFieldTarget)
      return;
    var defaultTab = this.useOneTimeValue ? 'one_time' : 'current_availability';
    this.handleLoad(defaultTab);
    this.setCurrentInterviewersAvailability(this.interviewerValue);
  }

  handleSelection(event) {
    this.processLocationType(event.currentTarget.getAttribute("for"));
  }

  handleLoad(location) {
    this.processLocationType(location);
  }

  processLocationType(location) {
    this.clearPreviousChoice();
    switch (location) {
      case "current_availability":
        this.handleCurrent();
        this.defaultToggleTarget.disabled = false;
        break;
      case "one_time":
        this.handleOneTime();
        this.defaultToggleTarget.checked = false;
        this.defaultToggleTarget.disabled = true;
        break;
      default:
        this.handleCurrent();
        this.defaultToggleTarget.disabled = false;
        break;
    }
  }

  handleCurrent() {
    this.oneTimeFormFieldTarget.value = false;
    this.currentTarget.classList.add("active");
    this.currentHolderTarget.style.display = "block";
    this.oneTimeHolderTarget.style.display = "none";
  }

  handleOneTime() {
    this.oneTimeFormFieldTarget.value = true;
    this.oneTimeTarget.classList.add("active");
    this.currentHolderTarget.style.display = "none";
    this.oneTimeHolderTarget.style.display = "block";
  }

  clearPreviousChoice() {
    this.optionTargets.forEach((option) => {
      option.classList.remove("active");
    });
  }

  updateCurrentAvailability(event) {
    this.setCurrentInterviewersAvailability(event.target.value);
  }

  setCurrentInterviewersAvailability(interviewer_id) {
    if(interviewer_id == ''){
      this.currentDataHolderTarget.innerHTML = 'Select an interviewer to see their current availability.';
      return;
    }

    axios.get("/manage/apps/" + this.appIdValue + "/autopilot/current_availability?user_id=" + interviewer_id).then((resp) => {
      this.currentDataHolderTarget.innerHTML = resp.data
    });
  }
}
