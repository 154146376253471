import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay"]

  connect() {
    // Show all overlays by default (Task Details tab)
    this.showAllOverlays()
  }

  initialize() {
    // Listen for tab changes from the parent tab-toggle controller
    document.addEventListener('tab-changed', this.handleTabChange.bind(this))
  }

  handleTabChange(event) {
    const selectedTab = event.detail.tab
    
    if (selectedTab === 'task details') {
      this.showAllOverlays()
    } else if (selectedTab === 'employee fields') {
      this.showOnlyEmployeeOverlays()
    } else if (selectedTab === 'manager fields') {
      this.showOnlyManagerOverlays()
    }
  }

  showAllOverlays() {
    this.overlayTargets.forEach(overlay => {
      overlay.classList.remove('d-none')
    })
  }

  showOnlyEmployeeOverlays() {
    this.overlayTargets.forEach(overlay => {
      if (overlay.dataset.fieldType === 'employee') {
        overlay.classList.remove('d-none')
      } else {
        overlay.classList.add('d-none')
      }
    })
  }

  showOnlyManagerOverlays() {
    this.overlayTargets.forEach(overlay => {
      if (overlay.dataset.fieldType === 'manager') {
        overlay.classList.remove('d-none')
      } else {
        overlay.classList.add('d-none')
      }
    })
  }
} 